.split-pane-col {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.split-pane-row {
  height: 100vh;
  flex-direction: row;
}

.split-pane-top,
.split-pane-bottom,
.split-pane-right,
.split-pane-left {
  flex: 1;
  overflow: hidden;
}

.split-pane-right {
  background-color: #fff;
  background-image: linear-gradient(
      90deg,
      transparent 79px,
      #abced4 79px,
      #abced4 81px,
      transparent 81px
    ),
    linear-gradient(#eee 0.1em, transparent 0.1em);
  background-size: 100% 1.2em;
}

.separator-col {
  border: 5px solid black;
  cursor: col-resize;
}

.separator-row {
  border: 5px solid black;
  cursor: row-resize;
}

